
.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: var(--headingColor);
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}