

.row-container {
  width: 100%;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: stretch;
  height: 75px;
  justify-content: center;
}

.row-line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  height: 100px;
  left: 0;
}

.row-container:hover {
  z-index: 2;
}

.grade {
  margin: 0 24px 0 0;
}

.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
}