.mapboxgl-popup {
  width: 150px;
  margin-left: 25px;
}

.mapboxgl-popup-content {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-left: -1px;
}

.mapboxgl-popup.hover {
  width: auto;
  max-width: none;
  pointer-events: none;
  display: block;
}

.mapboxgl-popup.hover .mapboxgl-popup-content {
  background: transparent;
  pointer-events: none;
  box-shadow: none;

}

.mapboxgl-popup.hover .mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-marker:hover {
  z-index: 10000 !important;
}

.mapbox-gl-marker.primary {
  z-index: 10 !important;
}

.map-guide-blue,
.map-guide-orange,
.map-area-blue,
.map-area-orange,
.map-problem-blue,
.map-problem-orange {
  transition: 0.2s ease-out all;
}

.map-gym-blue {
  width: auto;
  height: 35px;
  cursor: pointer;
}
.map-gym-orange {
  width: auto;
  height: 40px;
  cursor: pointer;
}

.map-guide-blue {
  width: auto;
  height: 30px;
  cursor: pointer;
}
.map-guide-orange {
  width: auto;
  height: 35px;
  cursor: pointer;
}

.map-area-blue {
  width: auto;
  height: 25px;
  cursor: pointer;
}
.map-area-orange {
  width: auto;
  height: 30px;
  cursor: pointer;
}

.map-problem-blue,
.map-route-blue,
.map-media-blue {
  width: auto;
  height: 25px;
  cursor: pointer;
}
.map-problem-orange,
.map-route-orange,
.map-media-orange {
  width: auto;
  height: 30px;
  cursor: pointer;
}

.map-guide-blue:hover,
.map-guide-orange:hover,
.map-area-blue:hover,
.map-area-orange:hover,
.map-problem-blue:hover,
.map-problem-orange:hover {
  transform: scale3d(1.2, 1.2, 1);
}
