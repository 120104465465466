@import "./common/colors.css";
@import "./common/typography.css";

* {
  box-sizing: border-box;
}

/** { background-color: rgba(255,0,0,.2); }
* * { background-color: rgba(0,255,0,.2); }
* * * { background-color: rgba(0,0,255,.2); }
* * * * { background-color: rgba(255,0,255,.2); }
* * * * * { background-color: rgba(0,255,255,.2); }
* * * * * * { background-color: rgba(255,255,0,.2); }
* * * * * * * { background-color: rgba(255,0,0,.2); }
* * * * * * * * { background-color: rgba(0,255,0,.2); }
* * * * * * * * * { background-color: rgba(0,0,255,.2); }
* * * * * * * * * * { background-color: rgba(255,0,255,.2); }
* * * * * * * * * * * { background-color: rgba(0,255,255,.2); }
* * * * * * * * * * * * { background-color: rgba(255,255,0,.2); }*/
html {
  font-size: 13px;
  overflow: auto;
}

/* body {
  margin: 0;
  font-family: var(--fontCopy);
  color: var(--textColor);
  background: var(--bgColor);
  line-height: 1.3;
} */

/* body {

  -webkit-print-color-adjust:exact !important;
  print-color-adjust:exact !important;
} */



.app {
  background: var(--bgColor);
  font-weight: normal;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
}

.container {
  width: 100vw;
  max-width: 1170px;
  flex: 1;
  padding: 80px 0;
  margin: auto;
  min-height: 500px;
}
.container.narrow {
  max-width: 620px;
  padding: 80px 10px;
}

@media screen and (max-width: 840px) {
  .container {
    padding: 80px 10px;
  }

  .container-header-hidden {
    padding: 0;
  }
}

button {
  background: transparent;
  border: none;
  outline: none;
}

/* using for icons, not italic */
i {
  font-style: normal;
}

a:focus {
  outline: none;
}

.strong {
  font-weight: 700;
}

/* Add your global styles below... */
/* a,
.link {
  display: inline;
  transition: 0.3s all ease-out;
  cursor: pointer;
  color: var(--colorBlue);
  text-decoration: none;
}

a:hover,
a:active,
.link:hover,
.link:active {
  text-decoration: underline;
} */

.pointer {
  cursor: pointer;
}

a:hover i,
a:active i,
.link:hover i,
.link:active i {
  text-decoration: none;
}

.card-link:hover:after {
  opacity: 0;
}

.link-white {
  color: var(--whiteColor);
  text-decoration: underline;
}

.link-white:after {
  background: var(--whiteColor);
  bottom: 2px;
}


a.no-hover-underline:hover,
.link.no-hover-underline:hover,
a.edit-btn {
  text-decoration: none;
}

.edit-btn {
  margin-left: 20px;
  display: block;
}

p,
.p {
  font-family: var(--fontCopy);
  color: var(--textColor);
  /* in order to line up with headings */
  padding-left: 1px;
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 0 0 15px;
}
p:last-child {
  margin-bottom: 0;
}

label {
  font-family: var(--fontCopy);
}

.capitalize.capitalize.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.none {
  display: none;
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--fontHeading);
  color: var(--headingColor);
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: 600;
  line-height: 1.5em;
}

h1,
h2 {
  line-height: 1.2em;
}

h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light,
.light {
  font-weight: 500;
}

/* --chakra-fontSizes-sm: 0.875rem;
--chakra-fontSizes-md: 1rem;
--chakra-fontSizes-lg: 1.125rem;
--chakra-fontSizes-xl: 1.25rem; */
.markdown-body h1, h1 {
  font-size: 2.5rem;
}

@media screen and (max-width: 480px) {
  .markdown-body h2, h2 {
    font-size: 1.125rem;
  }
}

.markdown-body h2, h2 {
  font-size: 1.25rem;
}

.markdown-body h3, h3 {
  font-size: 1.25rem;
}

.markdown-body h4, h4 {
  font-size: 1rem;
}

.markdown-body h5, h5 {
  font-size: 1rem;
}

.markdown-body h6, h6 {
  font-size: 0.875rem;
  line-height: 1.2em;
}

.markdown-body p {
  margin: 0 0 15px;
}

.markdown-body hr {
  margin-bottom: 15px;
}

h5.form-header {
  margin-top: 1.2rem;
  color: var(--textColor);
  font-size: 16px;
}

h6.note {
  font-size: 0.9rem;
  margin: 6px 0 5px;
  line-height: 1.3rem;
}

h6.note-error.note-error.note-error {
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.3rem;
  color: var(--colorOrange);
  margin-bottom: 10px;
}

.marked {
  font-family: var(--fontCopy);
  color: var(--textColor);
  font-size: 1.1rem;
  line-height: 1.5;
}

.marked ul,.marked ol {
  padding-left: 20px;
  margin: 0 0 15px;
}


ul.heading-list {
  color: var(--headingColor);
}
ul.heading-list li {
  color: var(--headingColor);
}
ul.modal-list {
  color: var(--colorWhite);
}
ul.modal-list li {
  color: var(--colorWhite);
}

.no-wrap {
  white-space: nowrap;
}

.wrap {
  white-space: normal;
}

.hidden {
  visibility: hidden;
}

.copy-font {
  font-family: var(--fontCopy);
}

.blue-text {
  color: var(--colorBlue);
}

.dark-blue-text {
  color: var(--headingColor);
}

.orange-text {
  color: var(--colorOrange);
}

.grey-text {
  color: var(--greyColor);
}

.black-text.black-text {
  color: var(--textColor);
}

.white-text {
  color: var(--colorWhite);
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.voffset {
  margin-top: 10px;
}

.voffset5 {
  margin-top: 5px;
}

.voffset15 {
  margin-top: 15px;
}

.voffset20 {
  margin-top: 20px;
}

.hoffset {
  margin-left: 5px;
  height: 100%;
  display: inline-block;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.height-0 {
  height: 0;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.no-margin.no-margin.no-margin {
  margin: 0;
}

.no-padding.no-padding {
  padding: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.list-unstyled {
  list-style: none;
  padding-left: 10px;
}

.absolute-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.relative {
  position: relative;
}

.no-pointer {
  pointer-events: none;
}

.flex-container {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.min-width-flex {
  min-width: 0;
}

.flex-center,
.vert-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.vert-align-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.vert-align-top-left {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}

.vert-align-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.vert-align-bottom {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
}

.align-bottom-left {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
}

.vert-align-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.vert-align-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.align-self-center {
  align-self: center;
}

.flex-right {
  margin-left: auto;
}

.flex-left {
  margin-right: auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

.weak {
  font-weight: 100;
}

.italic {
  font-style: italic;
}

.vertical-space {
  margin-top: 5px;
}

.vertical-space--8 {
  margin-top: 8px;
}

.vertical-space--10 {
  margin-top: 10px;
}

.vertical-space--16 {
  margin-top: 16px;
}

.vertical-space--20 {
  margin-top: 20px;
}

.vertical-space--30 {
  margin-top: 30px;
}

.vertical-space--60 {
  margin-top: 60px;
}

.vertical-space--neg16 {
  margin-top: -16px;
}

.horiz-space {
  display: inline-block;
  margin-left: 5px;
}

.horiz-space--8 {
  display: inline-block;
  margin-left: 8px;
}

.horiz-space--10 {
  display: inline-block;
  margin-left: 10px;
}

.horiz-space--16 {
  display: inline-block;
  margin-left: 16px;
}

.horiz-space--20 {
  display: inline-block;
  margin-left: 20px;
}

hr {
  width: 100%;
  border: none;
  border-top: 1px solid var(--headingLineColor);
}

/* autofill color */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
  color: var(--textColor);
}

.main-container {
  margin-top: 60px;
  padding: 20px;
}

.content-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.content-heading span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-chip {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.user-chip-img,
.user-chip-placeholder {
  flex: none;
  margin: 0 10px 0 0;
  height: 65px;
  width: 65px;
  background-size: cover;
  background-position: center;
  font-size: 34px;
  color: var(--colorBlue);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-chip-img.large,
.user-chip-placeholder.large {
  height: 80px;
  width: 80px;
}

.user-chip-placeholder {
  padding: 10px;
  color: var(--textColor);
}

.user-chip-text {
  flex: 1;
  align-self: center;
  width: calc(100% - 125px);
}

.user-chip-text h6 {
  margin: 2px 0;
}

.user-chip-text h5 {
  font-size: 14px;
}

.user-chip-heading {
  white-space: nowrap;
  width: calc(100% - 10px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-chip-heading.wrap {
  white-space: normal;
}

.user-circle-link {
  display: block;
}

.user-circle {
  height: calc(100% - 24px);
  background-size: cover;
  border: 1px solid #ccc;
  border-radius: 100%;
  background-position: center;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

/* styling other non-mdl inputs */
.react-rater {
  font-size: 20px;
  margin-right: auto;
  white-space: nowrap;
}

.react-rater .react-rater-star {
  color: var(--headingLineColor);
  fill: var(--headingLineColor);
}
.react-rater--dark .react-rater-star {
  color: var(--colorWhite);
  fill: var(--colorWhite);
}

.react-rater .react-rater-star:hover:not(.is-disabled),
.react-rater .react-rater-star.will-be-active {
  color: var(--buttonBlueHover);
  text-decoration: none;
}

.react-rater .react-rater-star.is-active,
.react-rater .react-rater-star.is-active-half:before {
  color: var(--colorBlue);
}
.react-rater--orange .react-rater-star.is-active,
.react-rater--orange .react-rater-star.is-active-half:before {
  color: var(--colorOrange);
  fill: var(--colorOrange);
}

.react-rater--small {
  position: relative;
  font-size: 12px;
}

.react-rater--medium {
  position: relative;
  top: -2px;
  font-size: 16px;
}

.react-rater__check {
  color: var(--greyColor);
  font-size: 28px;
  margin-right: auto;
  color: var(--greyColor);
}

.react-rater__check--small {
  font-size: 33px;
}

/* Custom inputs */
.textfield-button.textfield-button {
  position: relative;
  display: block;
  border: none;
  text-transform: none;
  border-bottom: 1px solid var(--headingLineColor);
  font-size: 16px;
  padding: 0 10px;
  margin: 17px 0 0 10px;
  height: 30px;
  color: var(--textColor);
  text-align: left;
  border-radius: 0;
  text-align: center;
  font-weight: 300;
  background: var(--bgColor);
}
.textfield-button.textfield-button:hover {
  background: none;
  color: var(--colorBlue);
}


.textfield-card.textfield-card.textfield-card {
  margin: 4px 0;
}

.textfield-button:hover {
  background: none;
  color: var(--colorBlue);
}

.textfield-button--selected {
  color: var(--textColor);
}

.modal-container {
  max-width: 720px;
  width: 100%;
  /* color: var(--colorWhite); */
  outline: none;
  max-height: 100vh;
  overflow: auto;
  padding: 10px;
  display: flex;
}
.modal-container.full {
  max-width: calc(100vw - 160px);
}

@media screen and (max-width: 600px) {
  .modal-container.full {
    max-width: 100vw;
  }
}

.modal-container > div {
  margin: auto;
}

/* .modal-container h1,
.modal-container h2,
.modal-container h3,
.modal-container h4,
.modal-container h5,
.modal-container h6 {
  color: #fff;
} */


.modal-container .section-heading {
  color: var(--colorBlue);
  border-bottom: 1px solid var(--colorBlue);
  margin: 10px 0 -10px;
}

.markdown-body ul,.markdown-body ol {
  padding: 0 0 0 20px;
  margin: 0 0 20px 0;
  font-family: var(--fontCopy);
  color: var(--textColor);
}


.modal-container .close-btn {
  position: fixed;
  top: 5px;
  right: 50%;
  margin-right: -340px;
  z-index: 1000;
}


@media screen and (max-width: 480px) {
  .modal-container .close-btn {
    top: -10px;
    right: -13px;
    margin-right: 0;
  }

  .modal-container .close-btn i {
    font-size: 32px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  overflow: auto;
  max-width: 100vw;
}

/* tooltip */
/* .__react_component_tooltip {
  left: -999em;
  position: fixed;
} */

.card-tooltip {
  transform-style: preserve-3d;
  max-width: 500px;
  text-align: left;
  z-index: 1000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  /* background: #fff !important; */
  color: var(--greyColor) !important;
  margin-top: 10px !important;
  padding: 4px 8px 3px !important;
}

.card-tooltip:before,
.card-tooltip:after {
  border: none !important;
}

.card-tooltip h6 {
  margin: 0;
  font-size: 12px;
  position: relative;
  z-index: 3;
}

.card-tooltip:after {
  display: none !important;
}

/* mapbox map */
.map__marker {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 16px 27.7px 16px;
  border-color: transparent transparent var(--greyColor) transparent;
}

/* date picker */
.datepicker-container {
  position: relative;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  font-family: var(--fontHeading);
  border-radius: 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  display: flex;

}

.react-datepicker__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: var(--whiteColor)
}

.react-datepicker-time__header {
  background-color: var(--whiteColor);
  color: var(--textColor);
}
.react-datepicker__month-container {
  background: var(--whiteColor);
}

.react-datepicker__current-month {
  font-weight: 300;
  color: var(--colorBlue);
}
.react-datepicker__time-box {
  background: var(--whiteColor);

}
.react-datepicker__time-list-item  {
  color: var(--textColor);
}

.react-datepicker__day--keyboard-selected {
  background: var(--whiteColor);
}
.react-datepicker__input-container {
  width: 100%;
  margin-top: 5px;
}

.react-datepicker__input-container input {
  font-family: var(--fontHeading);
  position: relative;
  display: block;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 14px;
  padding: 0;
  margin: 0;
  height: 30px;
  color: var(--textColor);
  text-align: left;
  width: 100%;
  height: 32px;
  cursor: pointer;
  color: var(--colorBlue);
  background: transparent;
  letter-spacing: .04em;
  padding: 6px 8px;
  -webkit-font-smoothing: antialiased;
}
.react-datepicker__input-container input::placeholder {
  color: var(--colorBlue);
}
.react-datepicker__triangle {
  border-top-color: transparent;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: var(--lightGrey);
}

.react-datepicker__input-container input:focus,
.react-datepicker__input-container input:hover {
  color: var(--colorBlue);
  outline: none;
}

.react-datepicker__day-name,
.react-datepicker__day {
  color: var(--textColor);
}

.react-datepicker__day--disabled {
  color: var(--greyColor);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background: var(--colorBlue);
  color: var(--whiteColor);
  font-weight: 400;
}

.react-datepicker__tether-element-attached-top
  .react-datepicker__triangle::before {
  border: none;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}

.button-loader {
  height: 24px;
  margin-top: -2px;
  margin-right: 7px;
}

.color-input {
  height: 30px;
  width: 30px;
  margin-left: 5px;
  border: none;
}

.alice-carousel__play-btn.alice-carousel__play-btn {
  left: 50%;
  bottom: -45px;
  top: auto;
  position: absolute;
  margin-left: -25px;
}
.alice-carousel__prev-btn.alice-carousel__prev-btn, .alice-carousel__next-btn.alice-carousel__next-btn {
  width: auto;
  left: 50%;
  bottom: -55px;
  top: auto;
  position: absolute;
  margin-left: -80px;

}
.alice-carousel__next-btn.alice-carousel__next-btn {
  margin-left: 20px;
}


.alice-carousel__dots.alice-carousel__dots {
  margin-top: 10px;
}

.alice-carousel__dots-item.alice-carousel__dots-item:not(.__custom) {
  background-color: var(--headingLineColor);
}

.alice-carousel__dots-item.alice-carousel__dots-item:not(.__custom).__active {
  background-color: var(--headingColor);
}

.alice-carousel__dots-item.alice-carousel__dots-item:not(.__custom):hover {
  background-color: var(--colorBlue);
}

.sortable-helper {
  z-index: 1000;
}

.chakra-portal {
  position: fixed;
  z-index: 10000;
}

.rw-forecast-icon {
  display: flex;
  justify-content: center;
}
.rw-container-header {
  display: none;
}

.demo-mode {
  overflow: auto !important;
}
nextjs-portal {
  display: none;
}

.mdx-editor {
  /* height: 200px; */
  border: 1px solid var(--borderColor) !important;
  
}
.mdxeditor-popup-container {
  position: relative;
  z-index: 10000 !important;
}

.mdxeditor-root-contenteditable {
  overflow: scroll;
  height: calc(100% - 42px);
}

.mdxeditor-root-contenteditable ul {
  padding-left: 20px;
  margin: 0 0 15px;
  font-family: var(--fontCopy);
  color: var(--textColor);
}
.mdxeditor-root-contenteditable h1,
.mdxeditor-root-contenteditable h2,
.mdxeditor-root-contenteditable h3,
.mdxeditor-root-contenteditable h4,
.mdxeditor-root-contenteditable h5,
.mdxeditor-root-contenteditable h6 {
  font-family: var(--fontHeading);
  color: var(--headingColor);
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: 600;
  line-height: 1.5em;
}