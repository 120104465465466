@import "../../../css/common/colors.css";
@import "../../../css/common/typography.css";

.nav {
  font-family: var(--fontHeading);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 62px;
  background: var(--colorWhite);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 10000;
}

.nav .nav__items {
  margin: auto;
  max-width: 1170px;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
}

@media screen and (max-width: 750px) {
  .nav .nav__items {
    position: absolute;
    flex-direction: column-reverse;
    width: 180px;
    background: var(--colorWhite);
    height: 100vh;
    right: -220px;
    top: 0;
    justify-content: flex-end;
    padding: 20px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-out all;
    align-items: flex-end;
  }

  .nav .nav__items.open {
    right: 0;
  }

  .nav .nav__item .item-link {
    color: var(--colorBlue);
  }
}

.nav .nav__item {
  position: relative;
  padding: 20px 30px;
}

@media screen and (max-width: 750px) {
  .nav .nav__item {
    margin: 0 -40px 0 0;
    text-align: right;
  }
}

.nav .item__submenu {
  transition: 0s opacity ease-out;
  position: absolute;
  left: 20px;
  top: calc(100% - 2px);
  text-align: left;
  white-space: nowrap;
  background: var(--colorBlue);
  border-radius: .3rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 1px 2px var(--textColor);
  opacity: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  padding: 0;
  min-width: 100%;
}

@media screen and (max-width: 750px) {
  .nav .item__submenu {
    height: auto;
    opacity: 1;
    background: transparent;
    box-shadow: none;
    position: relative;
    margin-top: 0;
    top: 0;
    left: 3px;
  }
}

.nav .nav__item:hover .item__submenu {
  transition: 0.3s opacity ease-out;
  opacity: 1;
  height: auto;
}

.nav .item__listitem {
  padding: 0 16px;
}

@media screen and (max-width: 750px) {
  .nav .item__listitem {
    padding: 10px 0 0;
    justify-content: flex-end;
  }
}

.nav .item__submenu-link {
  font-size: 1.1rem;
  font-family: var(--fontHeading);
  color: var(--colorWhite);
  padding: 16px;
}

.nav .item__submenu-link:hover {
  text-decoration: none;
  color: var(--colorWhite);
}

@media screen and (max-width: 750px) {
  .nav .item__submenu-link {
    color: var(--textcolor);
    padding: 0;
    text-align: right;
  }
}

.nav .item-link {
  position: relative;
  color: var(--colorBlue);
  font-size: 18px;
  font-weight: 600;
  bottom: -3px;
}

.nav .item-link:hover,
.nav .item-link.selected {
  color: var(--colorOrange);
}

.nav .item-link span {
  position: relative;
  z-index: 2;
}

.nav .item-link .item__background {
  transition: transform 0.4s ease-out;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--colorBlue);
  z-index: 1;
  height: 100%;
  transform: scale3d(1, 0, 1);
  transform-origin: top;
}

.nav .item-link:hover,
.nav .item-link:focus,
.nav .item-link:active {
  text-decoration: none;
}

.nav .item-link.active {
  color: var(--colorOrange);
}

/* .nav .item-link.active .item__background {
  transition: transform 0.2s ease-out;
  transform: scale3d(1, 1, 1);
  opacity: 1;
} */
.nav .logo-nav {
  padding-bottom: 24px;
  margin-left: -10px;
  margin-right: 52px;
}

/* @media screen and (max-width: 750px) {
  .nav .logo-nav {
    margin-right: 0;
    position: fixed;
    top: 0;
    left: 0;
    height: 60px;
    margin-top: -13px;
    margin-left: 0;
  }
} */
.nav .logo-cont,
.nav--login .logo-cont {
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 0 38px 1px 5px;
}



@media screen and (max-width: 750px) {
  .nav .logo-cont {
    position: fixed;
    top: 0;
    left: 10px;
  }
}

.nav--login .logo-cont {
  margin-right: 0;
  margin-left: 0px;
  flex-direction: column;
  height: 193px;
  margin-bottom: -90px;
}

@media screen and (max-width: 425px) {
  .nav--login .logo-cont {
    margin-bottom: -96px
  }
}

.nav .logo {
  width: 55px;
  margin-top: 3px;
  height: 55px;
  margin-right: 4px;
}

/* @media screen and (max-width: 750px) {
  .nav .logo {
    width: 45px;
  }
} */

.nav h1,
.nav--login h1 {
  font-family: var(--fontLogo);
  font-size: 36px;
  margin-top: 5px;
  color: var(--headingColor);
  font-weight: 500;
}

.nav--login h1 {
  font-size: 52px;
  color: #fff;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 425px) {
  .nav--login h1 {
    font-size: 40px;
    position: relative
  }
}

.nav .logo-line,
.nav--login .logo-line {
  border-left: 1px solid #ccc;
  height: 50px;
  display: block;
  margin: 5px 17px;
}

.nav--login .logo-line {
  display: none;
}

/* @media screen and (max-width: 750px) {
  .nav h1 {
    font-size: 24px;
  }
} */
.nav .sub-head.sub-head,
.nav--login .sub-head.sub-head {
  position: absolute;
  font-size: 12px;
  color: var(--colorBlue);
  bottom: -10px;
  right: 65px;
}

.nav--login .sub-head.sub-head {
  font-size: 19px;
  right: 3px;
  color: #fff;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  bottom: 37px;
}

@media screen and (max-width: 425px) {
  .nav--login .sub-head.sub-head {
    right: 2px;
    font-size: 14px;
    bottom: -26px;
  }
}

.nav .fab-button--login,
.nav--login .fab-button--login {
  box-shadow: none;
  overflow: visible;
  border: 1px solid var(--colorBlue);
  color: var(--colorBlue);
  background-color: var(--colorWhite);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.nav .fab-button--login:hover {
  color: color(var(--colorBlue) blackness(10%));
  background-color: color(var(--colorWhite) blackness(2%));
}

.nav--login {
  font-family: var(--fontHeading);
  z-index: 1;
  text-align: center;
  margin-top: 40px;
}

.nav--login .nav__items {
  margin: auto;
  max-width: 800px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.nav--login .nav__item {
  color: #fff;
  font-size: 22px;
  text-shadow: 0 0 4px #000;
  font-weight: 500;
  margin: 10px 50px -10px;
  flex: 1;
}

.nav--login a {
  color: #fff;
}

.nav--login .item-link:hover,
.nav--login .item-link:focus,
.nav--login .item-link:active {
  text-decoration: none;
  color: var(--colorBlue);
}

.nav--login .logo {
  height: 70px;
  margin-top: 20px;
  max-width: 180px;
}

@media screen and (max-width: 840px) {
  .nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .nav--login .nav__item {
    margin: 1vh 20px 0;
    font-size: 16px;
    padding-top: 40px;
  }

  .nav--login .nav__item--image {
    padding-top: 0;
  }
}

.right-menu {
  margin-left: auto;
  margin-right: 10px;
  display: flex;
  position: relative;
  z-index: 1000;
}

.hamburger.hamburger {
  display: none;
  position: relative;

  right: 20px;
  font-size: 20px;
}

@media screen and (max-width: 750px) {
  .right-menu {
    margin-right: -10px
  }

  .hamburger.hamburger {
    display: block;
  }

  .nav--login .hamburger {
    display: none;
  }
}

.close-menu.close-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.close-icon.close-icon {
  font-size: 20px;
}

@media screen and (max-width: 750px) {
  .close-menu.close-menu {
    display: block;
  }

  .close-menu.close-menu:before,
  .close-menu.close-menu:after {
    top: 5px;
    left: 7px;
  }

  .nav--login .close-menu {
    display: none;
  }
}