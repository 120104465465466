@import "../../../css/common/colors.css";
.container {
  position: relative;
  z-index: 2;
}
.container input {
  width: 100%;
}
.user-img,
.profile-place {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-list {
  max-height: 160px;
  overflow: auto;
  padding: 0 10px;
  position: absolute;
  top: 35px;
  background: var(--colorWhite);
  /* border: 1px solid var(--borderColor); */
  border-radius: 5px;
  left: 0px;
  right: 0px;
  min-width: 300px;
}

.search-item {
  padding: 5px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--headingLineColor);
  background-color: var(--colorWhite);
}

.search-item-bottom {
  padding: 5px 0;
  display: flex;
  align-items: center;

}

.search-item:last-child {
  border-bottom: none;
}
