.modal {
  padding: 0px;
  margin: -10px
}

.user-card {
  width: calc(50% - 8px);
  margin: 8px;
  height: 55px;
  position: relative;
}

.user-card:nth-child(odd) {
  margin-left: 0;
}

.user-card:nth-child(even) {
  margin-right: 0;
}

.user-create-card {
  height: 67px;
  overflow: visible;
  position: relative;
}

@media screen and (max-width: 480px) {
  .user-card {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}

.user-remove {
  position: absolute;
  top: 5px;
  right: 7px;
  cursor: pointer;
}

.registered-icon {
  background-color: var(--colorOrange);
  border-radius: 100%;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-invite.email-invite.email-invite {
  flex: 1;
  margin: -8px 0 0;
}
