.App {
  --colorBlack: #000;
  --colorWhite: #fff;
  --colorBlue: #03a9f4;
  --buttonBlueHover: #42b9f6;
  --colorOrange: #ff6e00;
  --headingLineColor: #bed5e0d5;
  --textColor: #204C6B;
  --placeholderColor: rgba(0, 0, 0, 0.38);
  --shadowColor: rgba(0, 0, 0, 0.5);
  --borderColor: rgba(0, 0, 0, 0.15);
  --buttonBkgrndColor: rgba(0, 0, 0, 0.05);
  --greyColor: rgba(0, 0, 0, 0.3);
  --modalBackground: rgba(0, 0, 0, 0.7);
  --bkgrndBlue: rgba(3, 169, 244, 0.1);
  --orangeColor: #FF6e00;
  --orangeTransparent: rgba(255, 110, 0, 0.5);
  --orangeColorHover: #EE6A06;
  --yellowColor: #FFA630;
  --redColor: #ff0000;
  --blueColor: #03A1F9;
  --blueTransparent: rgba(3, 161, 249, 0.5);
  --blueOverlay: rgba(3, 169, 244, 0.8);
  --blueBackground: rgba(3, 169, 244, 0.05);
  --headingColor: #20353F;
  --headingTransparent: rgba(32, 53, 63, 0.5);
  --headingLineColor: #B6D7EB;
  --blueColorHover: #0396E7;
  --cardBorder: #dddddd;
  --lightGrey: #cfcfcf;
  --lightBkgrnd: #efefef;
  --greyColor: #9B9B9B;
  --whiteColor: #ffffff;
  --whiteColorHover: #f5f5f5;
  --overlayColor: rgba(18, 61, 93, .8);
  --overlayAndroid: rgba(18, 61, 93, .9);
  --textColor: rgba(0, 0, 0, .6);
  --textLightColor: rgba(0, 0, 0, .4);
  --overlayBlack: rgba(0, 0, 0, .2);
  --btnBorderColor: #cedae0;
  --bgColor: #E9EEEF;
  --darkBGColor: #D4E1EA;
  --darkerBGColor: #D4E1EA;
  --darkestBGColor: #B6D7EB;
  --cardBorderLink: #35B3FA;
  --fbColor: #3a5998;
  --ggColor: #d84a38;
  --stravaColor: #fc4c02;
  --tabColor: #e9e9ee;
  --whiteOverlay: rgba(255, 255, 255, .6);
  --oldCardColor: rgb(245, 245, 245);
  --selectedHeadingColor: #996309;
  --placeholderColor: #8CB0C2;
  --inputBackgroundColor: rgba(0, 0, 0, 0.04);
  --startGradient: #D6EFFE;
  --endGradient: #A2C7DD;
  --sessionStatsGradSquare1: #C3E1F2;
  --sessionStatsGradSquare2: #BAD9EC;
}

.dark-mode {
  --colorBlack: #000;
  --colorWhite: #282C2E;
  --colorBlue: #64CDFD;
  --buttonBlueHover: #42b9f6;
  --colorOrange: #ff6e00;
  --bgColor: #323638;
  --headingColor: #C9D3DA;
  --headingLineColor: #525759;
  --textColor: #C9D3DA;
  --placeholderColor: rgba(0, 0, 0, 0.38);
  --shadowColor: rgba(0, 0, 0, 0.5);
  --borderColor: rgba(0, 0, 0, 0.15);
  --buttonBkgrndColor: rgba(0, 0, 0, 0.05);
  --greyColor: rgba(0, 0, 0, 0.3);
  --modalBackground: rgba(0, 0, 0, 0.7);
  --bkgrndBlue: rgba(3, 169, 244, 0.1);
  --orangeColor: rgb(230, 114, 25);
  --orangeTransparent: rgba(230, 114, 25, 0.5);
  --orangeColorHover: rgb(225, 120, 41);
  --yellowColor: rgb(186, 115, 21);
  --redColor: rgb(230, 25, 25);
  --blueColor: rgb(31, 157, 227);
  --blueTransparent: rgba(31, 157, 227, 0.5);
  --blueOverlay: rgba(36, 168, 227, 0.8);
  --blueBackground: rgba(36, 168, 227, 0.05);
  --headingColor: rgb(201, 210, 213);
  --headingTransparent: rgba(201, 210, 213, 0.5);
  --headingLineColor: #525657;
  --blueColorHover: rgb(47, 164, 229);
  --cardBorder: rgb(34, 34, 34);
  --lightGrey: rgb(48, 48, 48);
  --lightBkgrnd: rgb(29, 29, 29);
  --greyColor: rgb(100, 100, 100);
  --whiteColor: #282C2E;
  --whiteColorHover: rgb(23, 23, 23);
  --overlayColor: rgba(173, 203, 226, 0.8);
  --overlayAndroid: rgba(173, 203, 226, 0.9);
  --textColor: rgba(255, 255, 255, 0.6);
  --textLightColor: rgba(255, 255, 255, 0.4);
  --overlayBlack: rgba(255, 255, 255, 0.2);
  --btnBorderColor: rgb(39, 40, 41);
  --bgColor: rgb(32, 32, 32);
  --darkBGColor: rgb(27, 33, 37);
  --darkerBGColor: rgb(27, 33, 37);
  --darkestBGColor: rgb(29, 51, 64);
  --cardBorderLink: rgb(26, 125, 181);
  --fbColor: #3a5998;
  --ggColor: #d84a38;
  --stravaColor: rgb(228, 87, 28);
  --tabColor: rgb(32, 32, 32);
  --whiteOverlay: rgba(13, 13, 13, 0.6);
  --oldCardColor: rgb(23, 23, 23);
  --selectedHeadingColor: rgb(230, 188, 118);
  --placeholderColor: rgb(79, 91, 97);
  --inputBackgroundColor: rgba(255, 255, 255, 0.04);
  --startGradient: rgb(8, 40, 59);
  --endGradient: rgb(47, 68, 80);
  --sessionStatsGradSquare1: rgb(20, 41, 53);
  --sessionStatsGradSquare2: rgb(28, 48, 60);
  --chartColors: rgb(255, 255, 255);
}

