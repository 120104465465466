@import '../../../css/common/colors.css';
@import '../../../css/common/typography.css';

.footer {
  width: 100%;
  height: auto;
  background: var(--whiteColor);
  border-top: 1px solid var(--borderColor);
  position: relative;
  z-index: 100;
}
@media (max-width: 425px) {
  .footer {
    height: auto;

  }
}
.footer-fixed {
  transition: all 0.3s ease-out;
  composes: footer;
  position: fixed;
  bottom: 0;
}

.container {
  max-width: 1170px;
  margin: auto;
  position: relative;
  height: 100%;
  padding: 20px;
}

.logo {
  position: absolute;
  top: 10px;
  left: 10px;
}

.logo-img {
  width: 37px;
}

.logo-head {
  position: absolute;
  top: 7px;
  left: 37px;
  font-family: var(--fontLogo);
  font-size: 20px;
  color: var(--colorBlue);
}

.sub-head.sub-head {
  position: absolute;
  font-size: 5px;
  font-weight: bold;
  color: var(--headingColor);
  bottom: -11px;
  right: 2px;
}

.copy {
  position: absolute;
  bottom: 10px;
  font-size: 12px;
  left: 20px;
}

.social-btn {
  margin-top: 20px;
}

.nav {
  display: flex;
}

.section {
  flex: 1;
  padding: 0 10px;
}
