.slider-cont {
  position: relative;
  margin: 9px 7px 5px;
  padding: 0 2px;
}

.slider {
  position: relative;
  z-index: 2;
}

.color-cont {
  border-radius: 11px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin: -18px -10px 0;
  justify-content: space-between;
}

.color-bar {
  opacity: 0.9;
  height: 22px;
  overflow: hidden;
  position: relative;
  display: flex;
}
