@import "../../../css/common/colors.css";
.note-img {
  width: 35px;
  margin-right: 10px;
  border-radius: 100%;
}

.note-fab.note-fab {
  margin-right: 20px;
  overflow: visible;
  font-size: 30px;
  display: flex;
  align-items: center;
}

.note-fab-num {
  border-radius: 100%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 1px;
  right: 20px;
  background-color: var(--colorOrange);
  color: var(--colorWhite);
  font-size: 10px;
  padding-top: 1px;
}

.note-list-item.note-list-item.note-list-item {
  border-bottom: 1px solid #ccc;
  background: var(--bkgrndBlue);
  margin-bottom: 0;
  height: auto;
  padding: 10px 16px;
}
.note-list-item.note-list-item.note-list-item.is-opened {
  background: #fff;
}
.note-list-item.note-list-item.note-list-item:last-child {
  border-bottom: none;
}
.note-list-item.note-list-item.note-list-item a {
  width: 270px;
}
.note-list-item.note-list-item.note-list-item:hover {
  background: rgba(95, 179, 218, 0.25);
}
.note-list-item.note-list-item.note-list-item.is-opened:hover {
  background: color(#fff blackness(5%));
}

.note-copy {
  text-align: left;
  width: 100%;
  display: block;
  white-space: normal;
}
