.rc-slider-track {
  background-color: var(--colorBlue);
}

.rc-slider-handle {
  border: 1px solid #fff;
  background: var(--colorBlue);
  box-shadow: 0 0 3px var(--shadowColor);
}

.rc-slider-handle:hover {
  transform: scale3d(1.2, 1.2, 1);
  border: 1px solid #fff;
  box-shadow: 0 0 1px var(--shadowColor);
}

.rc-slider-handle:active {
  transform: scale3d(1.2, 1.2, 1);
  border: 1px solid #fff;
  box-shadow: 0 0 5px var(--greyColor);
}

.rc-slider-dot-active {
  border-color: var(--colorBlue);
}
