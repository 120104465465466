.confirm-modal {
  padding: 20px;
}

.description {
  margin: 10px 0 5px;
}

.list {
  margin: 0;
  line-height: 1.5em;
  padding: 10px 10px 0 20px;
}
